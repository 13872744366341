<template>
  <Layout :top-bar-ttle="title">
    <CustomView
      viewName="example_vue"
      v-model="object"
      :custom-component-catalog="customComponentCatalog"
    ></CustomView>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import CustomView from '../../../lib/uncustomui/components/CustomView'
import exampleComponent1 from './ExampleComponent1'
import exampleComponent2 from './ExampleComponent2'

export default {
  name: 'ExampleView',
  components: { CustomView, Layout },
  data () {
    return {
      title: 'Example view title',
      object: {
        firstProp: 'firstProp value'
      },
      customComponentCatalog: {
        'exampleComponent1': exampleComponent1,
        'exampleComponent2': exampleComponent2
      }
    }
  }
}
</script>

<style scoped>

</style>
