<template>
    <h1>Second component of the UnCustomUI example.</h1>
</template>

<script>
export default {
  name: 'ExampleComponent2'
}
</script>

<style scoped>

</style>
